import { render, staticRenderFns } from "./SectionForm.vue?vue&type=template&id=46505cfa&scoped=true"
import script from "./SectionForm.vue?vue&type=script&lang=js"
export * from "./SectionForm.vue?vue&type=script&lang=js"
import style0 from "./SectionForm.vue?vue&type=style&index=0&id=46505cfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46505cfa",
  null
  
)

export default component.exports